import React, { useEffect } from 'react';
import './App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Particles from 'react-tsparticles';

function App() {
  useEffect(() => {
    AOS.init({ duration: 1000 });  // Scroll animations
  }, []);

  return (
    <div className="app-container">
      {/* Particle background */}
      <Particles
        id="tsparticles"
        options={{
          particles: {
            number: { value: 50 },
            size: { value: 3 },
            move: { speed: 1 },
            line_linked: {
              enable: true,
              distance: 150,
              color: "#888",
              opacity: 0.4
            }
          }
        }}
      />
      <div className="centered-content" data-aos="fade-up">
        <h1 className="main-title" data-aos="zoom-in">Alshoubaki</h1>
        <p className="sub-title" data-aos="fade-in">Coming Soon</p>
      </div>
    </div>
  );
}

export default App;
